import { getMyUid } from '../my'
import { addGameCard, GAME_CARD_HERO, GAME_CARD_MAGIC, getMagik, prepareAndSetGameField } from './cardGameUtil'
import { setStoreWrapper } from '../StoreManager'
import { getCurrentRoomId } from '../navigation/navigationUtil'
import { GETRequest, POSTRequest } from '../api/requestWrapper'
import { getMiniGamesUrl } from '../api/apiURLUtil'
import { askQuiz } from '../quiz/quizUtil'
import { canBuyCheck } from '../checkBalanceUtil'
import { gameFinnish } from './cardGameTick'
import { closeCurrentPanel } from '../../components/Panels/createPanelUtil'
import { sendAmplitudeEvent } from '../GAEvent'

let gameInRoom
export function getRoomGame() {
  if (gameInRoom === getCurrentRoomId()){
    return
  }
  let loadedRoom = getCurrentRoomId()
  gameInRoom = getCurrentRoomId()
  GETRequest(getMiniGamesUrl('/api/v1/card-games/'+ loadedRoom), (game)=>{
    if (loadedRoom !== getCurrentRoomId()){
      return
    }
    startGame(game)
    // setStoreWrapper('gameBoss', game.boss)
    // prepareAndSetGameField(game.cards)
    // setStoreWrapper('gameField',  game.cards)
  })

  // setStoreWrapper('gameBoss', { hp: 193, strength: 10, boss_id: 'winner_6_2023', reward: { type: 'coins', value: 100 } })
  // setStoreWrapper('gameField',  [
  //   { uid: 'b088c5d81825b63ed382c9b4122af07d', card_id: 'healBossBig', card_type: GAME_CARD_MAGIC, strength: -100 },
  //   { uid: getMyUid(), card_id: 'winner_5_2023', card_type: GAME_CARD_HERO, strength: 13 },
  //   { uid: 'b088c5d81825b63ed382c9b4122af07d', card_id: 'lion_king_m', card_type: GAME_CARD_HERO, strength: 15 },
  //   { uid: 'b088c5d81825b63ed382c9b4122af07d', card_id: 'powerStrike', card_type: GAME_CARD_MAGIC, strength: 2 },
  //   { uid: 'b088c5d81825b63ed382c9b4122af07d', card_id: 'lightning', card_type: GAME_CARD_MAGIC, strength: 10 },
  //   { uid: 'e9ec9cf1f0e7becd8ed536a578f8215f', card_id: 'mice_cupid_m', card_type: GAME_CARD_HERO, strength: 15 },
  //   { uid: 'dd1e0b31a1486c727c52dc2139048901', card_id: 'mice_king_f', card_type: GAME_CARD_HERO, strength: 3 },
  // ])
}

export function startGame(game){
  setStoreWrapper('gameBoss', game.boss)
  prepareAndSetGameField(game.cards, )
}
export function buyGameCard(cardId, strength){
  const magikCard = getMagik(cardId)
  if (!magikCard){
    return
  }
  if (!canBuyCheck(magikCard.cost)){
    return
  }

  closeCurrentPanel()

  sendAmplitudeEvent('card_game', {
    type: 'buy_card',
    cardId,
    cost: magikCard.cost
  })

  POSTRequest(getMiniGamesUrl('/api/v1/card-games/' + getCurrentRoomId() + '/cards'), {  card_id: cardId }, (result)=>{
    //TODO хз нужно ли что-то делать, все равно будет WSS уведомление
  })

  //TODO костыль временный
  // addGameCard(cardId, getMyUid(), GAME_CARD_MAGIC, strength)
}

export function clearCardGame(){
  gameInRoom = null
  gameFinnish()
  setStoreWrapper('gameBoss', null)
  setStoreWrapper('gameField',  null)
}
