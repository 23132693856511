import { state } from 'cerebral'
import { getState } from '../../../utils/StoreManager'
import { getUser } from '../../../utils/userLoader'
import { isMe } from '../../../utils'
import { posterVisibleStream } from '../../../utils/ageRating/ageRatingUtil'
import isVip from '../../../utils/isVip'

export const updOnline = ({ props, store, get }) => {
  const onlineRooms = props.online
  const onlineObj = get(state`app.online`) || {}

  Object.keys(onlineObj).forEach(
    uid => onlineObj[uid] = false
  )

  let cleanRooms = {}
  let specs
  let uidInRoom
  let totalRooms = 0
  // let onlineVipCount = 0
  Object.keys(onlineRooms).forEach(
    roomID =>{
      uidInRoom = []
      specs = onlineRooms[roomID]
      specs.forEach(userId => {
        if (userId.length !== 32){
          return
        }
        uidInRoom.push(userId)
        // if (isVip(userId)){
        //   onlineVipCount++
        // }
        onlineObj[userId] = true
      })
      totalRooms++
      cleanRooms[roomID] = uidInRoom
    }
  )

  delete onlineObj['0']

  const users = get(state`app.users`) || {}
  Object.keys(onlineObj).forEach(
    uid => {
      if (onlineObj[uid] !== false) { return }
      delete onlineObj[uid]
      const user = users[uid]
      if (!user || !user.storage) { return }
      user.storage.logOutTime = new Date().getTime() / 1000
    }
  )
  
  store.set('app.users', users)
  store.set('app.online', onlineObj)
  store.set('app.roomSpectators', cleanRooms)
  store.set('app.onlineAmount', props.totalCount)
  store.set('totalRooms', totalRooms)
  // store.set('onlineVipCount', onlineVipCount)
}

//48 часов с момента логаута показываем как онлайн
const ONLINE_LAG = 48*60*60*1000

export function checkOnline(uid, realOnline = false) {
  if(isMe(uid)){
    return true
  }

  const online = getState('app.online')
  let user = getUser(uid)
  if (realOnline){
    if (online[uid]){
      return true
    }

    return false
  }
  if ((online[uid] || (
    user &&
    user.storage &&
    user.storage.logOutTime &&
    ((Date.now() - user.storage.logOutTime*1000) < ONLINE_LAG)))){
    return true
  }
  return false
}

export function getOnlineStatusForAmplitude(uid) {
  if(isMe(uid)){
    return 'me'
  }

  const online = getState('app.online')
  if (online[uid]){
    return 'online'
  }
  let user = getUser(uid)
  if (user &&
    user.storage &&
    user.storage.logOutTime &&
    ((Date.now() - user.storage.logOutTime*1000) < ONLINE_LAG)){
    return 'fake_online'
  }
  return 'offline'
}

export function getStreamStatusForAmplitude(uid) {
  if (isStreaming(uid)){
    return 'streaming'
  }

  if (isStreaming(uid, true)){
    return 'hidden_stream'
  }

  return 'no_stream'
}

export function isStreaming(uid, realStream = false) {
  const posters = getState('posters.raw')
  if (posters[uid] && (posterVisibleStream(uid) || realStream)){
    return true
  }

  return false
}

export function getLogoutTime(uid) {
  if(isMe(uid)){
    return Date.now()
  }

  const online = getState('app.online')
  if (online[uid]){
    return Date.now()
  }
  let user = getUser(uid)
  if (user &&
    user.storage &&
    user.storage.logOutTime){
    return user.storage.logOutTime*1000
  }

  return 0
}

export function getRoomSpectatorsCount(roomId) {
  const roomSpectators = getState('app.roomSpectators') || {}
  if (!roomSpectators[roomId]){
    return 0
  }
  if (roomSpectators[roomId].includes(roomId)){
    return roomSpectators[roomId].length - 1
  }
  return roomSpectators[roomId].length
}

export function getRoomSpectators(roomId) {
  const roomSpectators = getState('app.roomSpectators') || {}
  if (!roomSpectators[roomId]){
    return []
  }

  return roomSpectators[roomId].filter(uid => {
    return uid !== roomId
  })
}
