import { getState } from './StoreManager'
import { getMainAgeRating } from './ageRating/ageRatingUtil'
import { imModel } from './my'
import getStreamMetadata from './getStreamMetadata'
import { isModel } from './user/userInfos'
import { isTWA } from './twa'
import { getLocaleValue } from './localeUil'
import { COIN_ICON } from './stringUtils'
import getPrivateCost from './getPrivateCost'
import { isMobileSkin } from './checks'
import { state } from 'cerebral'
import { isStreaming } from '../modules/socket/actions'
import { getAppConfig } from './appConfigUtil'
import { getBaseImageUrl } from './urlUtils'

export function setDefaultTheme() {
  //если выбрана не одна тема, а несколько, то скинем в дефолтную тему
  const privateShowThemes = getPrivateThemes()
  const bannedThemes = getState('room.bannedPrivates')
  let availableThemesCount = 0

  //пройдемся по доступным темам и убедимся, что разрешена одна
  for (const themeId in privateShowThemes) {
    if (!bannedThemes[themeId]){
      availableThemesCount++
    }
  }

  //если больше 1 то нужно оставить одну
  if (availableThemesCount > 1){

  }
}

// export function setMyTheme() {
//   for (const themeId in privateShowThemes) {
//     if (!bannedThemes[themeId]){
//       availableThemesCount++
//     }
//   }
//   video_call_200
//   if (imModel()){
//     romantic_date_14
//   } else {
//     romantic_date
//   }
// }

export function getPrivateThemes() {
  const broadcastThemes = getState('intl.broadcastThemes')
  const privateShowThemes = {}
  const ageRating = getMainAgeRating()

  for (const themeId in broadcastThemes) {
    const themeData = broadcastThemes[themeId]
    if (themeData.type !== 'private' || themeData.age_rating > ageRating) {
      continue
    }
    privateShowThemes[themeId] = themeData
  }

  return privateShowThemes
}


//получает список тем приватов юзера
export function getUserPrivateThemes(uid) {
  const metadata = getStreamMetadata(uid)
  if (!metadata){
    return ['video_call_900', 'romantic_date_14', 'video_call_2400']
  }
  let privates = metadata && metadata.theme && metadata.theme.privateShowThemes
  if(privates){
    if (!(privates instanceof Array)){
      privates = ['video_call_900', 'romantic_date_14', 'video_call_2400']
    }
  }

  let manualSorted = []
  if (!privates){
    return privates
  }

  if (privates.includes('video_call_900')){
    manualSorted.push('video_call_900')
  }

  if (privates.includes('romantic_date_14')){
    manualSorted.push('romantic_date_14')
  }

  //theme_romantic_date_14
  if (privates.includes('video_call_2400')){
    manualSorted.push('video_call_2400')
  }

  // if (privates.includes('video_call_200')){
  //   manualSorted.push('video_call_200')
  // }

  // if (!isStreaming(uid, true)){
    return manualSorted
  // }

  // return manualSorted.reverse()

  /** Отключим авто выбор темы
   *
  //если у меня есть приваты и это не TWA то оставим только одну тему
  if (privates && !isTWA()){
    //если я модель, то одно из свиданий, если оно активно
    if (isModel(uid)){
      if (privates.includes('romantic_date_14')){
        privates = ['romantic_date_14']
        return privates
      }
      if (privates.includes('romantic_date')){
        privates = ['romantic_date']
        return privates
      }
    } else {
      if (privates.includes('video_call_200')){
        privates = ['video_call_200']
        return privates
      }
    }
  }
   */
  return privates
}

export function getDirectCallButtonText(uid, withCam) {
  const privates = getUserPrivateThemes(uid)//metadata && metadata.theme && metadata.theme.privateShowThemes

  return getLocaleValue('call')
  /** отключим цену на кнопке звонка
  if (privates && privates.length > 1) {
    return getLocaleValue('call')
  }
  const rawPosters = getState('posters.raw')
  if (privates && privates.length === 1) {
    if (isTWA() || !rawPosters[uid]){
      return getLocaleValue('call')
    } else {
      return (isMobileSkin() ? '' : getLocaleValue('call') + ' ') + COIN_ICON + getPrivateCost(withCam,privates[0] || 'romantic_date')
    }
  }
   */
}

export function isPrivateAvailable(uid) {
  const privates  = getUserPrivateThemes(uid)
  if (privates && privates.length > 0){
    //настройки конфига перекрывают настройки юзера
    const appConfig = getAppConfig()
    return !appConfig.privates_disabled
  }

  return false
}

export function isVIPOnlyCall(themeId){
  return themeId === 'video_call_200' || themeId === 'video_call_900'
}

