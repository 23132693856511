import { sequences, state } from 'cerebral'
import { updateAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { wait } from '@/utils'
import { loadUsers } from '@/utils/userLoader'
import { setMarketingMediums } from '../../intl/actions'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import { updOnline } from './updOnline'

let wasConnect = false

export const onconnect = async ({ store, get, path, props }) => {
  wasConnect = true
  for (let i = 0; i < 5; i++) {
    try {
      return handleOnConnect({ store, get, path, props })
    } catch (e) {
      console.log('@@@ onconnect error:', e)
      await wait(1000)
    }
  }
}

export const wasConnection = () => wasConnect

export const updateStreams = ({ store, get, streams }) => {
  let count = 0
  for (const uid in streams) {
    streams[uid].uid = uid
    count++
  }

  store.set('posters.wowzaStreamsCount', count)
  store.set('posters.wowzaStreamsRAW', streams)

  /**
  for (const uid in streams) {
    streams[uid].uid = uid
  }
  const myUid = get(state`auth.uid`)
  if (streams[myUid] && !get(state`room.streamInRoom`)) {
    delete streams[myUid]
  }
  store.set('posters.raw', streams)

  loadUsers(
    Object.keys(streams), 
    () => store.set('posters.postersCounter', get(state`posters.postersCounter`) + 1)
  )
   */
}

export const updateStreamsAnt = ({ store, get, streams }) => {
  let count = 0
  for (const uid in streams) {
    streams[uid].uid = uid
    count++
    if (!streams[uid].streamId){
      streams[uid].streamId = streams[uid].stream_id
    }
  }
  const myUid = get(state`auth.uid`)
  const proStream = get(state`room.proBroadcast`)

  if (streams[myUid] && !get(state`room.streamInRoom`) && !proStream) {
    delete streams[myUid]
  }
  const companionUid = get(state`room.companionUid`)

  //если у меня есть собеседник, и его стрима не стало в списке,
  //скорее всего он еще не стартовал и мы его ждем
  if (companionUid && !streams[companionUid]){
    const currentStreams = get(state`posters.raw`)
    const bd = currentStreams[companionUid]
    if (bd && bd.waitForStream){
      console.log("STILL WHAITING " + companionUid)
      streams[companionUid] = bd
    }
  }

  //если я жду про трансляцию, и его стрима не стало в списке,
  //скорее всего он еще не стартовал и мы ее ждем
  if (proStream){
    const currentStreams = get(state`posters.raw`)
    const bd = currentStreams[myUid]
    if (bd && bd.waitForStream){
      //если появилась полноценная трансляция, то закроем все панели
      if (streams[myUid]){
        const panelsOnPop = get(sequences`app.panelsOnPop`)
        panelsOnPop({ hideAll: true })
      } else {
        console.log("STILL WHAITING " + myUid)
        streams[myUid] = bd
      }
    }
  }
  store.set('posters.raw', streams)
  store.set('posters.antStreamsCount', count)

  loadUsers(
    Object.keys(streams),
    () => {
      store.set('posters.postersCounter', get(state`posters.postersCounter`) + 1)
    }
  )
}

const handleOnConnect = ({ store, get, path, props }) => {
  const route = get(state`app.route`)
  const roomId = get(state`app.roomId`)
  const {
    cards,
    country,
    streams,
    paidBroadcasts,
    online,
    privates,
    totalCount,
    streamsAnt
  } = props.params

  store.set('posters.privates', privates)
  store.set('socket.isConnected', true)
  store.set('buffs.cards', cards)
  
  store.set('auth.lastModerRating', getAgeRatingByUid(get(state`auth.uid`)))

  updateStreams({ store, get, streams })
  updateStreamsAnt({ store, get, streams:streamsAnt })
  store.set('app.paidRooms', paidBroadcasts)
  
  if (country) {
    store.set('intl.country', country.toLowerCase())
  } else {
    store.set('intl.country', 'us')
  }

  updateAgeRating({ store, get })
  setMarketingMediums({ store, get })
  
  try {
    updOnline({ props:{ online, totalCount }, store, get })
  } catch (error) {
    console.warn('cant parse online', online, error)
  }
  
  if (route === 'room') {
    return path.inRoom({ roomId })
  } else {
    return path.inOtherRoute()
  }
}
