import { set } from 'cerebral/factories'
import { state, props } from 'cerebral'
import controller from '@/controller'
import get from '@babel/runtime/helpers/esm/get'
import {
  MOBILE_LOCAL_VIDEO_H_MAX,
  MOBILE_LOCAL_VIDEO_H_MIN,
  MOBILE_MAX_LOCAL_VIDEO_H, switchSize
} from '../../../utils/localVideoUtil'
import { isMobileSkin } from '../../../utils'
import { setStoreWrapper } from '../../../utils/StoreManager'

let addedListener
let fullScreen
export default [
  async (props) => {
    if (isMobileSkin()){
      fullScreen = !fullScreen
      switchSize(fullScreen)
      setStoreWrapper('room.fullscreen' , fullScreen)
    } else {
      if (document.fullscreenEnabled) {
        if (!addedListener) {
          document.addEventListener('fullscreenchange', () => {
            fullScreen = Boolean(document.fullscreenElement)
            switchSize(fullScreen)
            setStoreWrapper('room.fullscreen' , fullScreen)
          })

          addedListener = true
        }
        fullScreen = !fullScreen
        if (document.fullscreenElement) {
          await document.exitFullscreen()
        } else {
          await document.getElementById(props.props.playerId || 'roomContent').requestFullscreen()
        }
        switchSize(fullScreen)
      } else {
        // const elem = document.getElementById('roomContent')
        // if (elem.webkitrequestFullscreen){
        //   if (!fullScreen){
        //     elem.webkitRequestFullScreen()
        //   }
        //     fullScreen = !fullScreen
        // }
      }
    }
  }
]
