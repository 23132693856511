import { getUserAgeRating } from '../ageRating/ageRatingUtil'
import { imFemale, imModel } from '../my'
import { isMe } from '../checks'
import { getUser } from '../userLoader'
import { FEMALE } from '../postUtils'

export function isModel(uid) {
  if (isMe(uid)){
    return imModel()
  }
  const user = getUser(uid)
  if (user && user.model > 0){
    return true
  }
  return getUserAgeRating(uid) !== 0
}

export function isFemale(uid) {
  if (isMe(uid)){
    return imFemale()
  }
  const user = getUser(uid)
  if (user && user.profile_post && user.profile_post.tags &&  user.profile_post.tags.includes(FEMALE)){
    return true
  }

  return false
}
