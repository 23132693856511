import { state } from 'cerebral'
import is from 'is_js'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'

export default [
  ({ get, store }) => {
    store.set('app.appRated', true)
    let fm = getState('fm') ||  {}
    fm.rated_twa_version =  get(state`app.twa_version`)
    store.set('fm', fm)
    let appId = get(state`app.appId`)
    sendAmplitudeEvent('rate_app', { reason: getState('askRateReason'), action: 'rate_click' })
    
    setTimeout(()=>{
      if (is.android()) {
        if (appId === 'flirty.chat') {
          appId = 'com.flirty.chat'
        }
        window.open(appId + '://rate', '_self', 'noopener noreferrer')
      } else {
        let safariHack = document.getElementById('safariHack')
        if (!safariHack){
          return
        }
        safariHack.setAttribute('href', encodeURI(`itms-apps://itunes.apple.com/app/id${getState('intl.appSourceConfig.appleId')}?action=write-review`))
        safariHack.click()
      }  
    }, 350)
  }
]
