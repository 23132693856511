import React from 'react'
import './levels.css'
import { string, any, oneOfType, object, number, bool } from 'prop-types'
import config from '@/config'
import { getLVLPicNumber, getLVLPicURL, getNextLVLPicNumber } from '../../utils/levelUtil'
import { getUserRating, isTopRated, isVerified } from '../../utils/userUtil'
import { getShowcasePicUrl } from '../../utils/showcase/getShowcase'
const { imageUrl, ftCoreUrl } = config

function getVerify(noVerify, uid, size) {
  return ((!noVerify && isVerified(uid)) ?  <i className='buttonIcon icon-verified_badge' style={{ fontSize: size, color: '#2096F3' }}/> : null)
}

function getTopRatedBadge(uid, size, maxStars) {
  if (isTopRated(uid) && maxStars){
    return (<img src={getShowcasePicUrl(Math.floor(getUserRating(uid)))}
               style={{ width: size, height:size }}/>)
  }

  return null
}

const LevelShape = ({
                      uid,
                      size,
                      nextLvlToUser,
                      noVerify,
                      maxStars,
                      style,
                      noStars
                    }) => {
  let picID
  if (nextLvlToUser){
    picID = getNextLVLPicNumber(uid)
  } else {
    picID = getLVLPicNumber(uid)
  }

  //в зависимости от размера - разные картинки
  let type
  if (size < 21){
    type = picID +'@chat'
  } else if (size < 36){
    type = picID +'@menu'
  } else {
    type = picID +'@big'
  }

  if (!style){
    style = { display: 'flex', alignItems: 'center' }
  }
  return (<div style={style}>
      {getVerify(noVerify, uid, size)}
      {picID > 0 &&
        <img src={`${imageUrl}/levels_new/${type}.png`} style={{
          width: size + 'px',
          height: size + 'px'
        }}/>
      }
      {!noStars && getTopRatedBadge(uid, size, maxStars)}
    </div>
  )
}

LevelShape.propTypes = {
  uid:string,
  size:number,
  nextLvlToUser:bool,
  maxStars:bool,
  noVerify:bool,
  noStars:bool,
  style:object
}

export default LevelShape
