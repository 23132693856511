import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import config from '@/config'
import { getMyUid } from '../../../utils/my'
import askRateApp from '../../../utils/askRateApp'
import { startAnimateGift } from '../../../components/Levels/animatedLike'
import { checkCurrentRoomId } from '../../../utils/navigation/navigationUtil'

const { soundUrl } = config

export default ({ props, store, get }) => {
  //giftSent:
  //{"gift":"wedding_rings","set":"romantic","from":"ead9c7682515efa1853415be2240aa70","to":"a146f2d6394603433e4a70943b4fb969", "cost":20000,}
  //giftSetSent
  //{"set":"romantic","from":"ead9c7682515efa1853415be2240aa70","to":"a146f2d6394603433e4a70943b4fb969", "cost":59000}
  if (!props.params) { return }

  const myUid = get(state`auth.uid`)
  const curRoom = get(state`app.roomId`) || myUid
  const msg = props.params

  if (msg.from !== myUid && msg.to !== myUid && !checkCurrentRoomId(msg.to)) {
    return
  }

  loadUsers([msg.from, msg.to],
    users => {
      msg.fromName = users[msg.from].name
      msg.toName = users[msg.to].name
      msg.postId = Date.now().toString(36)
      msg.fromNameTsc = users[msg.from].total_subscription_cost
      msg.toNameTsc = users[msg.to].total_subscription_cost
      
      const messages = get(state`chat.roomsMessages.${curRoom}`) || []
      messages.push(msg)

      const len = messages.length
      //эта магия c % 2 нужна, чтобы изменялоcь кол-во cообщений 
      //в маccиве при каждом новом cообщении
      while (messages.length > 24 && len % 2 === 0) {
        messages.shift()
      }

      startAnimateGift(msg.from, msg.gift)
      
      store.set(`chat.roomsMessages.${curRoom}`, messages)

      if (msg.from !== myUid && msg.to !== myUid) {
        return
      }
      const allDialogs = get(state`chat.dialogs`)
      const dialog = allDialogs.find(
        d => {
          if (d.users_in_dialog.length !== 2) return false
          const otherUid = d.users_in_dialog.filter(uid => uid !== myUid)[0] || getMyUid()
          return otherUid === msg.to || otherUid === msg.from
        }
      )
      if (!dialog || !dialog.messages) {
        return
      }
      dialog.messages.unshift({
        date: new Date().toISOString(),
        dialog_id: dialog.dialog_id,
        message: msg.gift,
        message_type: 'gift',
        uid: msg.from
      })
      dialog.message = msg.gift
      store.set('chat.dialogs', allDialogs)
    }
  )

  if (msg.to === myUid) {  
    //дополнительный таймаут в 7 секунд
    setTimeout(()=>{
      askRateApp('gift_received')
    }, 7000)

    try {
      const url = msg.cost < 5000 ?   
        soundUrl + 'gift1.mp3' :
        soundUrl + 'gift2.mp3'
      const s = new Audio(url)
      s.play().catch(() => console.warn('sound not allowed'))
    } catch (e) {
      console.warn('sound not allowed')
    }
  }
}
