import { set, wait, when } from 'cerebral/factories'
import { state } from 'cerebral'
import routeToRoom from './routeToRoom'
import panelsOnPop from './panelsOnPop'
import { isBroadcastBlocked } from '@/utils/buffUtils'
import { buffPanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import sendCreateBroadcast, { saveMetadata } from '../../room/signals/sendCreateBroadcast'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { AFK, GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18, MAN_ON_CAM, WRONG_FORMAT } from '../../buffs'
import { LIVE_PERMISSION } from '@/utils/subscriptionUtil'
import { topicCreate } from '../../../utils/amplitudeLiveEvents'

export default [
  ({ path }) => {
    const buffId = isBroadcastBlocked()
    if (buffId) {
      return path.forbidden({ buffId })
    } else {
      return path.allowed()
    }
  },{
    forbidden:[
      ({ props, get }) => buffPanel(props.buffId, get(state`auth.uid`), null),
      createPanel,
    ],
    allowed:[
      () => ({ hideAll: true }),
      panelsOnPop,
      wait(260),
      ({ get }) => ({ roomId: get(state`auth.uid`) }),
      routeToRoom,
      wait(600),
      when(state`room.streamInRoom`), {
        true: sendCreateBroadcast,
        false: [
          set(state`room.streamInRoom`, state`auth.uid`),
          topicCreate,
          saveMetadata
        ]
      },
    ]
  },
]
