import controller from '@/controller'
import { isMe } from './checks'
import { callSequence } from './StoreManager'
import { NO_LIKE_AVAILABLE, QUIZ, QUIZ_COOL_DOWN } from '../modules/buffs'
import { getMyUid } from './my'

export const isBroadcastBlocked = () => {
  const myUid = controller.getState('auth.uid')
  const buffs = controller.getState(`app.users.${myUid}.multi_buffs`) || {}

  for (const buffId in buffs) {
    const cardInfo = controller.getState(`buffs.cards.${buffId}`) || {}
    if (!cardInfo.blocksBroadcasting) { continue }
    return buffId
  }

  return false
}

export const getBuffTimeleft = (buffId, uid, fromUid) => {
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  if (!buffs[buffId]) { return null }

  const allBuffs = buffs[buffId] || []
  const buffData = allBuffs.find(
    b => b.from === fromUid || !fromUid
  )
  if (!buffData) { return null }
  const startTime = buffData ? buffData.start * 1000 : 0
  const start = new Date(startTime)
  
  const cards = controller.getState('buffs.cards')
  const cardInfo = cards[buffId]
  
  return cardInfo && cardInfo.duration && (cardInfo.duration - (Date.now() - start))
}

export const getBuffUTCEndTime = (buffId, uid, fromUid) => {
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  if (!buffs[buffId]) { return null }

  const allBuffs = buffs[buffId] || []
  const buffData = allBuffs.find(
    b => b.from === fromUid || !fromUid
  )
  if (!buffData) { return null }
  const startTime = buffData ? buffData.start * 1000 : 0
  const start = new Date(startTime)

  const cards = controller.getState('buffs.cards')
  const cardInfo = cards[buffId]

  return cardInfo && cardInfo.duration && new Date(cardInfo.duration + startTime)
}

export const filterBuffs = (multi_buffs, userId) => {
  const superAdmin = controller.getState('auth.superAdmin')
  const admin = controller.getState('auth.admin')
  const cardData = controller.getState('buffs.cards')
  const myUid = controller.getState('auth.uid')

  if (!multi_buffs){
    return {}
  }

  return Object.keys(multi_buffs)
    .filter(buffId => {
      const cardInfo = cardData[buffId]
      if (!cardInfo) { return false }
      if (superAdmin || admin) { return true }
      if (buffId === QUIZ) { return false }
      if (buffId === QUIZ_COOL_DOWN) { return false }
      if (cardInfo.visible === 'owner' && userId !== myUid) { return false }
      if (cardInfo.visible === 'admin') { return false }
      if (getBuffTimeleft(buffId, userId,multi_buffs[buffId].fromUid) < 0){
        return false
      }
      return true
    })
    .map(buffId => multi_buffs[buffId])
    .flat(1)
}

export function addBuffNew(uid, buffId, fromUid) {
  callSequence('buffs.addBuff', { buff: { cardType: buffId, toUid: uid, startTime: Date.now(), fromUid: fromUid } })
}

export const isBuffActive = (uid, buffId, fromUid = null) => {
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  /*
  multi_buffs: {
    1013: [
      {
        from: "6f29b9e66605467680430ed47f926f3c",
        id: 1013,
        start: 1627039818
      }
    ]
  }
  */

  if (!buffs[buffId]) { return false }
  
  const cardInfo = controller.getState(`buffs.cards.${buffId}`) || {}
  buffs[buffId] = buffs[buffId].filter(
    b => {
      const start = b.start * 1000
      const timePassed = Date.now() - start
  
      return cardInfo.duration && timePassed < cardInfo.duration
    }
  )
  controller.flush()

  const multiBuff = buffs[buffId] || []
  for (let i = 0; i < multiBuff.length; i++) {
    const buff = multiBuff[i]
    if (fromUid && buff.from !== fromUid) { continue }
    return true
  }
  
  return false
}
