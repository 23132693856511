import { getState } from '../StoreManager'
import { getAppId } from '../twa'
import is from 'is_js'

const IOS_FM_APP_ID = 'com.flirtymania.videochat'
const IOS_UV_APP_ID = 'ulive.tv'
const AND_UV_APP_ID = 'com.tv.ulive'

export const MS_PWA = '27386FamilyTrain.FlirtymaniaDatingChat'

export function isIOSPWA() {
  const appId = getAppId()
  return appId === IOS_FM_APP_ID || appId === IOS_UV_APP_ID
}

export function isRestrictedMode(){
  // return true
  const appID = getAppId()
  return appID === IOS_UV_APP_ID || appID === AND_UV_APP_ID
}

export function iosPWABuyClick(params) {
  try {
    window.webkit.messageHandlers.PwaSend.postMessage(
      JSON.stringify( { pay: params })
    )

    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  } catch(err) {
    alert(err)
    // window.webkit.messageHandlers.test.postMessage(JSON.stringify(params))
  }
}

export function openLinkHack(url){
  if (isIOSPWA()) {
    let safariHack = document.getElementById('safariHack')
    if (!safariHack) {
      window.open(url, 'noopener noreferrer')
      return
    }
    safariHack.setAttribute('href', url)
    safariHack.click()
    return
  }

  window.open(url, 'noopener noreferrer')
}
