import { getState, setStoreWrapper, shiftStoreWrapper } from '../StoreManager'
import { prepareAndSetGameField } from './cardGameUtil'
import { getCurrentRoomId } from '../navigation/navigationUtil'

const TICK_DURATION = 1500

const ANIMATE_DURATION = 800

export const ANIMATE_HEAL = 'heal'
export const ANIMATE_FIREBALL = 'fireball'
export const ANIMATE_HIT = 'hit'

let tickInterval
export function gameStart(){
  // if (tickInterval){
  //   clearInterval(tickInterval)
  // }
  // tickInterval = setInterval(tickAction, TICK_DURATION)
}

export function gameFinnish(){
  if (tickInterval){
    clearInterval(tickInterval)
  }
}

//возьмем объект боса и применим к нему одну карточку игры
function tickAction(){
  const boss = getState('gameBoss')
  if (!boss){
    return
  }

  const data = getState('gameField')
  let gameCard
  if (data){
    gameCard = data[0]
  }

  if (!gameCard){
    return
  }

  // boss.hp -= gameCard.strength
  // if (boss.hp < 0){
  //   boss.hp = 0
  // } else {
  if (gameCard.strength < 0){
    setStoreWrapper('animateBoss', ANIMATE_HEAL)
  } else {
    if (gameCard.card_id === ANIMATE_FIREBALL){
      setStoreWrapper('animateBoss', ANIMATE_FIREBALL)
    } else {
      setStoreWrapper('animateBoss', ANIMATE_HIT)
    }
  }
    
    // setTimeout(()=>{
    //   setStoreWrapper('animateBoss', false)
    // }, ANIMATE_DURATION)
  // }
  // setStoreWrapper('gameBoss', boss)
  // if (boss.hp === 0){
  //   gameFinnish()
  // }
}

export function syncGame(game){
  if (game.room_id !== getCurrentRoomId()){
    return
  }
  tickAction()
  // gameStart()
  // setStoreWrapper('animateBoss', true)
  setTimeout(()=>{
    setStoreWrapper('gameBoss', game.boss)
    prepareAndSetGameField(game.cards)
  }, ANIMATE_DURATION)
}
