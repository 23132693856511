import { state } from 'cerebral'
import { sendToWS2 } from '../ws'
import { getSessionId } from '../cookies'
import { addGameCard, cardGameNewCards, cardGameWinner } from './cardGameUtil'
import { clearCardGame, startGame } from './cardGameAPI'
import { syncGame } from './cardGameTick'
import { messageTypes } from '../../modules/app/signals/onIframeMessage'

let instance
export function createGameWSS(){
  if (instance) {
    instance.close()
  }
  authSession = null
  instance = null

  instance = new WebSocket('wss://ft-minigames.faminta1.com/ws')
  instance.onopen = () => { authGameSocket() }
  instance.onmessage = ({ data }) => onmessage({ data })
  instance.onclose = ({ wasClean, code, reason }) => onclose({ wasClean, code, reason })
  instance.onerror = ({ message }) => { console.log(message) }
}

async function onmessage(data){
  let json
  try {
    json = await JSON.parse(data.data)
  } catch (e) {
    json = data
  }
  const { method, params } = json
  console.log(data)

  switch (method) {
    case 'cardGameNewCard':
      return addGameCard(params.card)
    case 'cardGameWinner':
      return cardGameWinner(params)
    case 'cardGameTick':
      return syncGame(params)
    //добавление карточек пачкой
    case 'cardGameNewCards':
      return cardGameNewCards(params)
    case 'cardGameStart':
      return startGame(params)
  }
}

function onclose(data){
  setTimeout(()=>{
    createGameWSS()
  }, 1000)
}

export function sendMessage(method, params, errorCb){
  const data = JSON.stringify(params ? { method, params } : { method })
  try {
    if (instance && instance.readyState !== undefined && instance.readyState === instance.OPEN && instance.readyState !== instance.CONNECTING) {
      instance.send(data)
    } else {
      console.error('game wss error ' + data)
      if (errorCb){
        errorCb()
      }
    }
  } catch (e) {
    console.error(data)
    if (errorCb){
      errorCb()
    }
  }
}

let authSession
export function authGameSocket(){
  if (!getSessionId()){
    return
  }
  if (getSessionId() === authSession){
    return
  }
  authSession = getSessionId()
  sendMessage('authorize', { sid: getSessionId() }, ()=>{
    authSession = null
    setTimeout(()=>{ authGameSocket() }, 1000)
  })
}
