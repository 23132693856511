import { getThemeData } from '@/utils/themeUtils'
import controller from '@/controller'

const getPrivateCost = (withCam, themeId) => {
  if (!themeId) {
    const settings = controller.getState('intl.settings') || {}
    const ageRating = controller.getState('app.ageRating')

    let noThemeCost = ageRating > 17 ? settings.privateMinuteCostPlus : settings.privateMinuteCostRegular
    if (withCam) {
      noThemeCost = Math.round(noThemeCost * settings.privateShowWithCamFactor)
    }
    return noThemeCost
  }

  const { cost, cost_with_cam } = getThemeData(themeId)
  return withCam ? cost_with_cam : cost
}

export default getPrivateCost
