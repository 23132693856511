import { getUser } from '../userLoader'
import { isFemale } from '../user/userInfos'
import { getUserStableRandom } from '../user/getUserStableRandom'
import { getAllFreeFemaleCharacters, getAllFreeMaleCharacters, getVIPCharacters } from './awardsConfig'
import { imVIP } from '../my'
import isVip from '../isVip'

export function getUserMainCharacter(uid) {
  const user = getUser(uid)

  //если нет юзера, то не показываем картинку, что бы лишнее не моргало
  if (!user){
    return null
  }

  if (user.awards && user.awards[0]){
    return user.awards[0].award
  }

  let characters
  //если есть юзер, но нет картинки - подставим какую-то в соттветствии с полом
  if(isVip(uid)){
    characters =  getVIPCharacters(isFemale(uid))
  }  else {
    characters = isFemale(uid) ? getAllFreeFemaleCharacters() : getAllFreeMaleCharacters()
  }

  return characters[getUserStableRandom(uid, characters.length)]
}

export function getVIPCharacter(uid){
  const characters =  getVIPCharacters(isFemale(uid))
  return characters[getUserStableRandom(uid, characters.length)]
}
