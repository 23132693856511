import { getAwardConfig } from '../awards/awardsConfig'
import { getSettings } from '../settingsUtil'
import { pushStoreWrapper, setStoreWrapper, unshiftStoreWrapper } from '../StoreManager'
import { getMyUid } from '../my'
import { getUserMainCharacter } from '../awards/getUserMainCharacter'
import { getUser, loadUsers } from '../userLoader'
import { getCurrentRoomId } from '../navigation/navigationUtil'
import { gameFinnish } from './cardGameTick'
import { clearCardGame } from './cardGameAPI'
import { wait } from '../wait'
import { isMe } from '../checks'
import { sendAmplitudeEvent } from '../GAEvent'

export const GAME_CARD_HERO = 'hero'
export const GAME_CARD_MAGIC = 'magic'

export const GAME_CARD_BOSS_HEAL = 'boss_heal'
export const GAME_CARD_ATTACK = 'attack'

export const GAME_REWARD_EXP = 'exp'
export const GAME_REWARD_COINS = 'coins'

export const GS_IN_PROGRESS = 'in_progress'
export const HERO_WON = 'hero_won'

export function getStrength(cardId){
  const award = getAwardConfig(cardId)

  //если это не карточка, проверим на магию
  if (!award){
    const magik = getMagik(cardId)
    if (!magik){
      return 1
    }
    return magik.strength
  }

  const gameHeroes = getGameHeroesConfig()
  if (!award.hero_class){
    if (gameHeroes.default){
      return gameHeroes.default.strength
    }
  }

  const heroClass = gameHeroes[award.hero_class]
  if (heroClass){
    return heroClass.strength
  }

  return 1
}

export function getHealth(cardId){

}

export function prepareGameField(cards){

}

export function getGameHeroesConfig() {
  return getSettings('card_game_heroes') || {}
}

export function getMagikConfig() {
  return getSettings('card_game_magic') || {}
}

export function getMagik(cardId){
  return getMagikConfig()[cardId]
}

//добавление пачкой
export function cardGameNewCards(cards){
  prepareCards(cards.new_cards, (prepared)=>{
    prepared.forEach(gameCard => {
      // count++
      // setTimeout(()=>{
        pushStoreWrapper('gameField', { uid: gameCard.uid, card_id: gameCard.card_id, card_type: gameCard.card_type, strength: gameCard.strength, date: Date.now() })
        // addGameCard(gameCard.card_id, gameCard.uid, gameCard.card_type, gameCard.strength)
      // }, 10)
    })
  })
}

export function addGameCard(gameCard){
  prepareCards([gameCard], (prepared)=>{
    //пришел массив карточек, достанем первую
    const preparedCard = prepared[0]
    if (preparedCard.card_type === GAME_CARD_MAGIC){
      const magik = getMagik(preparedCard.card_id)
      if (magik.priority < 0){
        // addGameCard(gameCard.card_id, gameCard.uid, gameCard.card_type, gameCard.strength)
        // setStoreWrapper('uk', { uid: uid, card_id: cardId, card_type: type, strength: strength, date: Date.now() } )
        unshiftStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
      } else {
        pushStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
        // pushStoreWrapper('gameField', { uid: uid, card_id: cardId, card_type: type, strength: strength, date: Date.now() })
      }
    } else {
      // setTimeout(()=> {
      pushStoreWrapper('gameField', { uid: preparedCard.uid, card_id: preparedCard.card_id, card_type: preparedCard.card_type, strength: preparedCard.strength, date: Date.now() })
      // }, 10)
    }
  })
}

const DEFAULT_HERO = 'default'
const DEFAULT_VIP_HERO = 'vip'

export function getRealCard(cardId, uid){
  if (cardId === DEFAULT_HERO){
    return getUserMainCharacter(uid)
  }
  return cardId
}

export function prepareAndSetGameField(cards){
  prepareCards(cards, (preparedCards)=>{
    setStoreWrapper('animateBoss', null)
    setStoreWrapper('gameField',  preparedCards)
  })
}

export function prepareCards(cards, cb){
  const newUsers = []
  cards.forEach(gameCard => {
    newUsers.push(gameCard.uid)
  })

  if (newUsers.length){
    loadUsers(newUsers, ()=>{
      cards.forEach(gameCard => {
        if (gameCard.card_id === DEFAULT_HERO || gameCard.card_id === DEFAULT_VIP_HERO){
          gameCard.card_id = getUserMainCharacter(gameCard.uid)
        }
      })
      cb(cards)
    })
  }
}

export function getMagicCardsByType(type){
  const magikCards = getMagikConfig()
  const cards = []
  Object.keys(magikCards).map(cardId => {
    const card = magikCards[cardId]
    // атака дефолтный тип, поэтому он не прописан
    if (type === GAME_CARD_ATTACK){
      if (!card.type){
        cards.push({ ...card, card_id:cardId })
      }
    } else if (card.type === type){
      cards.push({ ...card, card_id:cardId })
    }
  })

  return cards
}

export function cardGameWinner(data){
  if (isMe(data.winner)){
    sendAmplitudeEvent('card_game', {
      type: 'winner',
      reward: data.reward.reward_type,
      value: data.reward.reward_value
    })
  }
  if (data.room_id === getCurrentRoomId()){
    clearCardGame()
  }
  console.log(data)
}
