import { getState } from './StoreManager'
import { checkCountry } from './ageRating/ageRatingUtil'
import { isTWA } from './twa'

export function isPaymentAvailable() {
  const paymentSystems = getState('intl.appSourceConfig.paymentSystems')
  if (!paymentSystems || paymentSystems.length < 1){
    return false
  }

  return true
}

// export function swapPayment(appSourceConfig){
//   if (checkCountry('ru') && !isTWA()){
//     appSourceConfig.paymentSystems = ['po']
//   }
// }
