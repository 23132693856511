import { getState } from './StoreManager'
import { COIN_500_ICON } from './stringUtils'

export function getSettings(key) {
  return (getState('intl.settings') || {})[key]
}

export function getSettingsInt(key) {
  return getSettings(key) || 0
}

export function getSettingsCost(key) {
  return COIN_500_ICON + getSettingsInt(key)
}

export function getPostPhotoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl){
    return null
  }
  if (isProdEnv){
    return settingsUrl.photos_url_prod + fileName
  }
  return settingsUrl.photos_url_dev + fileName
}

export function getPostVideoUrl(fileName) {
  let settingsUrl = getSettings('posts_media_urls')
  if (!settingsUrl){
    return null
  }
  if (isProdEnv){
    return settingsUrl.videos_url_prod + fileName
  }
  return settingsUrl.videos_url_dev + fileName
}
