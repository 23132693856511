import { loadUsers } from '@/utils/userLoader'
import { checkTextForBadWords } from './checkChatMessage'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { isQuizInRoom, quizInChat } from '../../../utils/quiz/quizUtil'
import { loadQuizInfo } from '../../../utils/quiz/quizAPI'

export default ({ props, store }) => {
  if (!props.history) {
    if (isQuizInRoom()){
      loadQuizInfo(true)
    }
    return
  }
  
  const noBadWordsHistory = props.history
  //   .filter(
  //   ({ text }) => !checkTextForBadWords(text)
  // )
  
  const uids = []
  for (let i = 0; i < noBadWordsHistory.length; i++) {
    const msg = noBadWordsHistory[i]
    uids.push(msg.from)
    if (msg.to) {
      uids.push(msg.to)
    }
  }
  loadUsers(uids, users => {
    for (let i = 0; i < noBadWordsHistory.length; i++) {
      const msg = noBadWordsHistory[i]
      if (users[msg.from]) {
        msg.fromName = users[msg.from].name
        msg.fromNameTsc = users[msg.from].total_subscription_cost
      }
      if (msg.to) {
        msg.toName = users[msg.to].name
        msg.toNameTsc = users[msg.to].total_subscription_cost
      }
    }
    store.set('chat.roomsMessages.' + props.roomId, noBadWordsHistory)
    if (isQuizInRoom()){
        loadQuizInfo(true)
    }
  })
}
