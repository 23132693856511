import * as Sentry from '@sentry/react'
import amplitude from 'amplitude-js'
import { state } from 'cerebral'
import controller from '@/controller'
import config from '@/config'
import { getParentDomain } from './urlUtils'
import { COIN_ICON } from './stringUtils'
import { isTWA } from '@/utils/twa'
import { getCookie, setCookie, deleteCookie } from './cookies'
import isVip from './isVip'
import { getMyRealAge } from './user/ageUtil'
import { addMeInTopUids } from './topBar/topBarFillUtil'
import { getState } from './StoreManager'
import { getUser } from './userLoader'
import { getMyUid, imFemale, isMyProfileSet } from './my'
import { getUserStableRandom } from './user/getUserStableRandom'
import { getExp } from './levelUtil'
import { isModel } from './user/userInfos'
import { isRTL } from '../ui/isRTL'


const GA = (...args) => {
  try {
    ga(...args)
  } catch (e) {}
}

export const amplitudeApiKey = isProdEnv ? 'cced522ce9c9060d79aa7aac0d837c23' : 'd21063815e03882b51bd503d598d406d'

let inited = false
const sendAfterInit = []
const fmchat = 'com.flirty' + 'mania.js'

export function SendAnalitycs(category, data, action, label = '', value = 0) {
  if (!inited) {
    sendAfterInit.push({ category, data, action, label, value })
    return
  }
  GAEvent(category, action, label, value)
  sendAmplitudeEvent(category, data)
}

export function TrackTargetsEvent(ymId) {
  try {
    dataLayer.push({ 'event': 'start_broadcast' })
    ym(ymId,'reachGoal','start_translation')
    fbq('track', 'start_translation')
  } catch (e) {
    console.log('TrackTargetsEvent error', e)
  }
}

export default function GAEvent(category, action, label = '', value = 0, pal = null) {
  GA('gtm2.set', '&pal', pal)

  category = category ? category.toLowerCase() : 'unknown'
  action = action ? action.toLowerCase() : 'unknown'
  label = label ? label.toLowerCase() : ''
  GA('gtm2.send', 'event', category, action, label, value)
}

/*
paymentSystem: 'cb',
periods: [
  {
    cost: 9.99,
    duration: 7
  },
  {
    cost: 29.99,
    duration: 30
  }
]
*/
export function trackEcommerceVipImpression(paymentSystem, periods) {
  const label = controller.getState('shop.openReason') || 'click-vip-panel'
  
  if (!periods || periods.length === 0) return

  for (let i = 0; i < periods.length; i++) {
    const productData = getVipProduct(paymentSystem, periods, i, label)
    
    GA('gtm2.ec:addImpression', productData)
    GA('gtm2.ec:addProduct', productData)
  }

  GA('gtm2.ec:setAction', 'detail')
  GAEvent('ecommerce', 'detail', label, 0, label, true)
}

function getVipProduct(paymentSystem, periods, index, label) {
  const name = periods[index].duration === 7 ? 'Weekly' :
                periods[index].duration === 30 ? 'Monthly' : 
                periods[index].duration + '_days'
  return {
    id: location.host + '.' + name.toLowerCase(),
    name: name,
    category: paymentSystem,
    price: periods[index].cost,
    quantity: 1,
    list: label
  }
}

export function trackEcommerceCoinsImpression(paymentSystem) {
  const label = controller.getState('shop.openReason') || 'click-shop-coins'

  const products = controller.getState('shop.items')
  if (products.length === 0) return
  
  for (let i = 0; i < products.length; i++) {
    const productData = getProduct(products[i], i, label, paymentSystem)
    
    GA('gtm2.ec:addImpression', productData)
    GA('gtm2.ec:addProduct', productData)
  }

  GA('gtm2.ec:setAction', 'detail')
  GAEvent('ecommerce', 'detail', label, 0, label, true)
}

function getProduct(product, index, label, paymentSystem) {
  return {
    id: location.host + '.coins_' + (index + 3),
    name: product.price.toLocaleString('en-US') + ' coins',
    category: paymentSystem,
    price: Math.ceil(product.modePrice),
    quantity: 1,
    list: label
  }
}

export function vipItemClick(paymentSystem, periods, idx, isCrypto) {
  const label = controller.getState('shop.openReason') || 'click-vip-panel'
  
  const productData = getVipProduct(paymentSystem, periods, idx, label)
  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'click')
  GAEvent('ecommerce', 'click', label, 0, label, true)

  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'add')
  GAEvent('ecommerce', 'add', label, 0, label, true)

  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'checkout')
  GAEvent('ecommerce', 'checkout', label, 0, label, true)

  sendAmplitudeEvent('product_click', {
    reason: label,
    coins: 0,
    price: productData.price,
    payment_system: isCrypto ? 'pa' : paymentSystem
  })
}

export function itemClick(product, index, paymentSystem, isCrypto) {
  const label = controller.getState('shop.openReason') || 'click-shop-coins'

  const productData = getProduct(product, index, label, paymentSystem)

  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'click')
  GAEvent('ecommerce', 'click', label, 0, label, true)

  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'add')
  GAEvent('ecommerce', 'add', label, 0, label, true)

  GA('gtm2.ec:addProduct', productData)
  GA('gtm2.ec:setAction', 'checkout')
  GAEvent('ecommerce', 'checkout', label, 0, label, true)

  const setLastClickedPack = controller.getSequence('shop.setLastClickedPack')
  setLastClickedPack({ pack: productData })

  sendAmplitudeEvent('product_click', {
    reason: label,
    coins: product.price,
    price: product.modePrice,
    payment_system: isCrypto ? 'pa' : paymentSystem
  })
}

export const onVipPurchase = params => {
  const label = controller.getState('shop.openReason') || 'click-vip-panel'

  const price = parseFloat(params.price)
  const revenue = new amplitude.Revenue().setPrice(price)
  .setQuantity(1)
  .setRevenueType('revenue')
  .setEventProperties({
    id: params.transaction_id,
    reason: label,
    payment_system: params.payment_system,
    price: params.price,
    coins: 0
  })

  amplitude.getInstance().logRevenueV2(revenue)

  const userProps = new amplitude.Identify()
  userProps.set('vip', true)
  amplitude.getInstance().identify(userProps)
  
  const subsData = controller.getState('intl.settings.vip_subscriptions')
  const paymentSystem = Object.keys(subsData)[0]
  const periods = subsData[paymentSystem]
  let idx = -1
  for (let i = 0; i < periods.length; i++) {
    if (Math.abs(parseFloat(periods[i].cost) - price) < 0.05) {
      idx = i
    }
  }
  if (idx === -1) { return }

  addMeInTopUids()
  
  const productData = getVipProduct(params.payment_system, periods, idx, label)
  if (productData) {
    GA('gtm2.ec:addProduct', productData)
    GA('gtm2.ec:setAction', 'purchase', productData)
  }
}

export const onPurchase = params => {
  const showSnackbar = controller.getSequence('app.showSnackbar')
  const intl = controller.getState('intl.content')
  showSnackbar({
    text: intl.successful_purchase_head + ' +' + params.add_coins + COIN_ICON,
    type: 'coin',
  })

  console.log('onPurchase', params)
  if (params.is_test) { return }
  
  const label = controller.getState('shop.openReason') || 'click-shop-coins'
  let productData = controller.getState('shop.lastClickedPack') 

  if (!productData) {
    const products = controller.getState('shop.items')
    
    const product = products.find(pr => pr.price === params.add_coins) || products[0]
    if (product) {
      productData = getProduct(product, products.indexOf(product), label, params.payment_system)
    }
  }

  if (productData) {
    GA('gtm2.ec:addProduct', productData)
    GA('gtm2.ec:setAction', 'purchase', productData)
  }
  
  GAEvent('ecommerce', 'purchase', label, 0, label, true)

  const revenue = new amplitude.Revenue().setPrice(parseFloat(params.mode_price))
                                         .setQuantity(1)
                                         .setRevenueType('revenue')
                                         .setEventProperties({
                                           id: params.transaction_id,
                                           reason: label,
                                           payment_system: params.payment_system,
                                           coins: params.add_coins
                                         })

  amplitude.getInstance().logRevenueV2(revenue)

  checkCookiesOnPurchase()
}

//инициируем стабильный процент юзеров
const STABLE_PERCENT = 5 //отключил, возможно не влезем в лимиты

function isAmplitudeUser(){
  const uid = getMyUid()

  // const sr = getUserStableRandom(uid, 100)
  // if (getUserStableRandom(uid, 100) < (STABLE_PERCENT + 1)){
  //   return true
  // }
  /** если не попал в топ 30% то проверим интересный ли это юзер */

  //есть опыт
  if (getExp(uid)){
    return true
  }

  //есть на балансе монеты
  if (getState('auth.purchasedCoins')){
    return true
  }

  //или доллары
  if (getState('auth.receivedCoins')){
    return true
  }

  //или заполнен профиль
  if (isMyProfileSet()){
    return true
  }

  //или он автор
  if (isModel(uid)){
    return true
  }

  return false
}

let amplitudeInited  = false

const initAmplitude = ({ get }) => {
  const uid = get(state`auth.uid`)
  //для влезания в MTU бесплатного тарифа
  if (!isAmplitudeUser()){
    amplitudeInited = false
    return
  }
  amplitudeInited = true
  amplitude.getInstance().init(amplitudeApiKey, uid, {
    includeUtm: true,
    includeReferrer: true
  })

  let app_id = get(state`app.appId`)
  // Если не TWA
  if (!isTWA()) {
    // Если PWA
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
      app_id = 'pwa'
    } else {
      app_id = fmchat
    }
  }

  const me = getUser(uid)
  const rid = controller.getState('auth.partnerId')
  const identify = new amplitude.Identify()
                                .set('app_id', app_id)
                                .set('subjects', get(state`app.subject`) || 'without_subject')
                                .set('user_id', uid)
                                .set('vip', !!isVip(uid))
                                .set('balance', get(state`auth.coins`))
                                .set('balance_usd', get(state`auth.usdBalance`))
                                .set('age_rating', get(state`app.ageRating`))
                                .set('nsfw', get(state`auth.stuff.allowNsfw`) || false)
                                .set('account_type', getAccountType({ get }).toLowerCase())
                                .set('account_loyalty', getAccountLoyalty())
                                .set('explore_type', '3.0')
                                .set('referrer_id', rid || '-1')
                                .set('age', getMyRealAge())
                                .set('date_reg', me.dateReg)
                                .set('female', imFemale())
                                .set('rtl', isRTL())
                                .set('ui_locale', get(state`auth.lang`) || get(state`intl.lang`))

  if (isTWA()) {
    identify.set('app_installer_id', get(state`app.twa_app_installer`) || getParentDomain())
            .set('app_name', get(state`app.twa_app_name`) || 'fm-js')
            .set('app_version', get(state`app.twa_version`) || AppBuildTime)
  } else {
    identify.set('app_installer_id', getParentDomain())
            .set('app_name', 'fm-js')
            .set('app_version', AppBuildTime)
  }

  amplitude.getInstance().identify(identify)
  setTimeout(checkCookies, 30000)
}

const checkCookies = () => {
  try {
    const fStep = getCookie('funnel_step')
    if (fStep !== 'step1') { return }

    setCookie('funnel_step', 'step2', { expires: 60 * 60 * 24 * 3 })
    if (typeof ym === 'function') {
      ym(47906846,'reachGoal','30_sec')
    }
    dataLayer.push({ 'event': '30_sec' })
  } catch (e) {
    console.warn(e)
  }
}

const checkCookiesOnPurchase = () => {
  try {
    const fStep = getCookie('funnel_step')
    if (fStep !== 'step2') { return }

    deleteCookie('funnel_step')
    if (typeof ym === 'function') {
      ym(47906846,'reachGoal','buy_subscription')
    }
    dataLayer.push({ 'event': 'buy_subscription' })
  } catch (e) {
    console.warn(e)
  }
}

const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId)
}

const sendPaidEventToGA = (eventType, eventProperties) => {
  let action = ''
  let label = ''
  switch (eventType) {
    case 'gifts':
      action = 'send'
      label = eventProperties.gift_id
      break
    case 'message':
      action = 'send'
      label = eventProperties.message_category_1
      break
    case 'users_content':
      action = 'view'
      label = eventProperties.content_category
      break
    case 'topic_pay':
      action = eventProperties.product_type
      label = eventProperties.broadcast_type
      break
    case 'subscriptions':
      action = eventProperties.action
      label = eventProperties.type
      break
    default: 
      action = eventProperties.action || 'send'
      break
  }
  
  GAEvent(eventType, action, label, eventProperties.sent_coins)
}

let lastSendedEvent = null
let lastSendedTime = 0

export const sendPercentAmplitudeEvent = (eventType, eventProperties) => {
  if (Math.random() > 0.1) { return }
  sendAmplitudeEvent(eventType, eventProperties)
}
export const sendAmplitudeEvent = (eventType, eventProperties, force) => {
  //не шлем событие если амплитуда не заинициировалась
  if (!amplitudeInited){
    //TODO можно добавить форсированную инициализацию на ряд событий
    if (!force){
      return
    }
  }
  if (eventType === 'message' && !eventProperties.sent_coins) {
    //поcылаем бесплатные сообщения c 10% вероятноcтью
    if (Math.random() > 0.1) { return }
  }

  if (!eventProperties){
    eventProperties = {}
  }

  eventProperties.loyalty_old = getAccountLoyalty()
  eventProperties.loyalty_new = getAccountLoyalty(true)

  if (eventProperties.sent_coins > 0) {
    sendPaidEventToGA(eventType, eventProperties)
  }
  
  const userProps = new amplitude.Identify()
  userProps.set('balance', controller.getState('auth.coins'))
  userProps.set('balance_usd', controller.getState('auth.usdBalance'))
  amplitude.getInstance().identify(userProps)

  console.log('@@@ send amplitude event', eventType, eventProperties)
  //ловим ошибку, что бы сентури не ругался
  amplitude.getInstance().logEvent(eventType, eventProperties, () => {}, () => {console.log('oef')})

  lastSendedEvent = eventType
  lastSendedTime = Date.now()
}

const getAccountType = ({ get }) => {
  const isModel = get(state`auth.model`) > 0
  let accountType = 'User'
  if (isModel) accountType = 'Model'

  return accountType
}

const getAccountLoyalty = (days) => {
  let accountLoyalty = 'newcomer'
  let dif = 0
  const day = 24 * 60 * 60 * 1000

  try {
    const dateReg = Date.parse(new Date(getState('auth.dateReg')))
    dif = Date.now() - dateReg

    if (dif > 21 * day) {
        accountLoyalty = 'senior'
    } else if (dif > 14 * day) {
        accountLoyalty = 'mature'
    } else if (dif > 7 * day) {
        accountLoyalty = 'medium'
    } else if (dif > 2 * day) {
        accountLoyalty = 'involved'
    }
  } catch (e) {
    console.log('parse date error: ' + e)
  }
  if (days){
    return Math.floor(dif/day)
  }
  return accountLoyalty
}

export const initGA = ({ get }) => {
  const uid = get(state`auth.uid`)
  const ageRating = get(state`app.ageRating`)
  const accountType = getAccountType({ get })
  
  const accountLoyalty = getAccountLoyalty()
  
  if (!inited) {
    GA('gtm2.require', 'ec')

    GA('gtm2.set', 'appName', 'fm-js')
    GA('gtm2.set', 'appId', fmchat)
    GA('gtm2.set', 'appVersion', config.version)
    
    const parentHost = getParentDomain()
    
    GA('gtm2.set', 'appInstallerId', parentHost)
    //GA('gtm2.send', 'pageview', '/new-chat')
    
    inited = true

    const fullLoadTime = Date.now() - window.startLoadingTime
    
    GA('gtm2.send', 'timing', 'app_load_time', 'first_load', fullLoadTime)

    //на всякий случай таймаут, что бы все данные точно были в церебрале
    setTimeout(()=>{
      initAmplitude({ get })
    }, 1000)


    //поcылаем аналитику c 1% вероятноcтью
    if (Math.random() < 0.01) {
      const onlyAppLoadTime = (window.endLoadingTime - window.startLoadingTime) || 0
      const getAuthInfoTime = Date.now() - window.endLoadingTime
      const loginCount = get(state`auth.loginCount`)
  
      sendAmplitudeEvent('app_load_time', {
        get_auth_info_time: (getAuthInfoTime / 1000).toFixed(2),
        oauth_time: window.oauth_time ? (window.oauth_time / 1000).toFixed(2) : -1,
        only_app_load_time: (onlyAppLoadTime / 1000).toFixed(2),
        full_load_time: (fullLoadTime / 1000).toFixed(2),
        first_load_flag: loginCount === 0 ? '1' : '0'
      })
    }

    const now = new Date()
    const serverTimeOffset = now.getTimezoneOffset() * 60 * 1000
    
    const serverTime = new Date(now.getTime() + serverTimeOffset) 
    const lastLogInTime = new Date((get(state`auth.storage.logOutTime`) || 0) * 1000 + serverTimeOffset)
    
    if (serverTime.getDay() !== lastLogInTime.getDay() || serverTime.getDate() !== lastLogInTime.getDate()) {
      sendAmplitudeEvent('user_appearance', {})
    }
  }
  GA('gtm2.set', 'userId', uid)
  GA('gtm2.set', 'dimension1', ageRating + '')
  GA('gtm2.set', 'dimension2', accountType)
  GA('gtm2.set', 'dimension3', accountLoyalty)

  setAmplitudeUserId(uid)

  while (sendAfterInit.length > 0) {
    const params = sendAfterInit.pop()
    SendAnalitycs(params.category, params.data, params.action, params.label, params.value)
  }
  
  Sentry.configureScope(scope => {
    scope.setUser({ 'id': uid })
  })
}

export const initABCTest = ({ get, store }) => {
  const customParams = get(state`auth.customParams`)
  if (!getState('intl.settings')){
    setTimeout(() =>{
      initABCTest({ get, store })
    }, 500)
    return
  }
  if (!customParams || Object.keys(customParams) === 0) {
    return
  }

  const userProps = new amplitude.Identify()

  for (const settingParam in customParams) {
    const { group, testNum, value } = customParams[settingParam]
    
    store.set(`intl.settings.${settingParam}`, value)
    if (testNum && group) {
      userProps.set('abc_test' + testNum, group)
    }
  }
  amplitude.getInstance().identify(userProps)
}

